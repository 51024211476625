import first from "lodash/first";
import last from "lodash/last";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { getDayCount } from "app/utils/utils";
import { get, isEmpty } from "lodash";
import { FLIGHT_TYPE, ITINERARY_INFO_TYPE } from "app/constants";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

export const legInformation = stopCount => {
	return stopCount > 0 ? (
		<Typography variant={TYPOGRAPHY_VARIANTS.SMALL} className="stop-duration" isBold>
			<FormattedPlural
				value={stopCount}
				one={
					<FormattedMessage
						values={{
							value: stopCount,
						}}
						id="general.stop.singular"
					/>
				}
				other={
					<FormattedMessage
						values={{
							value: stopCount,
						}}
						id="general.stop.plural"
					/>
				}
			/>
		</Typography>
	) : (
		<Typography variant={TYPOGRAPHY_VARIANTS.SMALL} className="stop-duration" isBold>
			<FormattedMessage id="general.transport.direct" />
		</Typography>
	);
};

export const buildItineraryPreviewData = flight => {
	if (isEmpty(flight) || get(flight, "legs", []).length === 0) {
		return [];
	}

	const firstLeg = first(flight.legs);
	const lastLeg = last(flight.legs);
	const stopCount = flight.legs.length - 1;
	const legInfo = legInformation(stopCount);
	const isFlightStopOver = flight?.legs?.some(
		leg => leg?.from?.isStopOver || leg?.to?.isStopOver
	);

	const arrivalDaysCounter = getDayCount(firstLeg.from.takeOffDate, lastLeg.to.landingDate) || 0;

	const timelineData = [];
	const departEvent = {
		type: ITINERARY_INFO_TYPE.SCHEDULE,
		data: {
			airport: firstLeg.from.airport,
			iata: firstLeg.from.iata,
			iataCity: firstLeg.from.iataCity,
			date: firstLeg.from.takeOffDate,
			time: firstLeg.from.takeOffTime,
			...(isFlightStopOver && { isFlightStopOver: true }),
		},
		flightType: FLIGHT_TYPE.DEPARTURE,
	};
	const arrivalEvent = {
		type: ITINERARY_INFO_TYPE.SCHEDULE,
		data: {
			airport: lastLeg.to.airport,
			iata: lastLeg.to.iata,
			iataCity: lastLeg.to.iataCity,
			date: lastLeg.to.landingDate,
			time: lastLeg.to.landingTime,
			arrivalDaysCounter: arrivalDaysCounter,
			...(isFlightStopOver && { isFlightStopOver: true }),
		},
		flightType: FLIGHT_TYPE.ARRIVAL,
	};
	const flightInfo = {
		type: ITINERARY_INFO_TYPE.FLIGHT_INFO,
		data: {
			duration: flight.totalDuration,
			stopCount: stopCount,
			legInfo: legInfo,
			airlineCode: flight.airline.code,
			...(isFlightStopOver && { isFlightStopOver: true }),
		},
	};

	timelineData.push(departEvent);
	timelineData.push(flightInfo);
	timelineData.push(arrivalEvent);
	return timelineData;
};

export const buildItineraryDetailData = flight => {
	if (isEmpty(flight) || get(flight, "legs", []).length === 0) {
		return [];
	}

	const timelineData = [];
	const hasTransit = get(flight, "legs", []).length > 1;
	const firstLeg = first(flight.legs);
	flight.legs.forEach((leg, index) => {
		const isArrivalNextDay =
			(getDayCount(firstLeg.from.takeOffDate, leg.to.landingDate) || 0) > 0;
		const isDepartNextDay =
			(getDayCount(firstLeg.from.takeOffDate, leg.from.takeOffDate) || 0) > 0;
		const legInfo = (
			<Typography variant={TYPOGRAPHY_VARIANTS.SMALL} className="stop-duration" isBold>
				<FormattedMessage id="general.transport.direct" />
			</Typography>
		);
		const departEvent = {
			type: ITINERARY_INFO_TYPE.SCHEDULE,
			data: {
				airport: leg.from.airport,
				iata: leg.from.iata,
				iataCity: leg.from.iataCity,
				date: leg.from.takeOffDate,
				time: leg.from.takeOffTime,
				isNextDay: isDepartNextDay,
				...(leg?.from?.isStopOver && { isStopOver: leg?.from?.isStopOver }),
			},
		};
		const arrivalEvent = {
			type: ITINERARY_INFO_TYPE.SCHEDULE,
			data: {
				airport: leg.to.airport,
				iata: leg.to.iata,
				iataCity: leg.to.iataCity,
				date: leg.to.landingDate,
				time: leg.to.landingTime,
				isNextDay: isArrivalNextDay,
				...(leg?.to?.isStopOver && { isStopOver: leg?.to?.isStopOver }),
			},
		};
		const flightInfo = {
			type: ITINERARY_INFO_TYPE.FLIGHT_INFO,
			data: {
				duration: leg.to.flightDuration,
				airlineCode: leg.airline.code,
				airlineName: leg.airline.name,
				flightNumber: leg.flightNumber,
				isDirect: !hasTransit,
				...(leg.operatingAirlineName && {
					operatingAirlineName: leg.operatingAirlineName,
				}),
				...(!hasTransit && { legInfo: legInfo }),
			},
		};
		timelineData.push(departEvent);
		timelineData.push(flightInfo);
		timelineData.push(arrivalEvent);

		if (index < flight.legs.length && leg.to.stopDuration) {
			let transit = {};
			if (leg?.to?.isStopOver) {
				transit = {
					type: ITINERARY_INFO_TYPE.STOPOVER_INFO,
					data: {
						duration: leg.to.stopDuration,
					},
				};
			} else {
				transit = {
					type: ITINERARY_INFO_TYPE.TRANSIT_INFO,
					data: {
						duration: leg.to.stopDuration,
					},
				};
			}
			timelineData.push(transit);
		}
	});

	return timelineData;
};

export const convertDurationLabel = duration => {
	if (duration && duration.length > 0) {
		const durationItems = duration.split(":");
		if (durationItems.length === 2) {
			return `${durationItems[0]}h ${durationItems[1]}m`;
		}
		if (durationItems.length === 3) {
			return `${durationItems[0]}d ${durationItems[1]}h ${durationItems[2]}m`;
		}
	}
	return "";
};

export const buildStopOverItineraryPreviewData = (flight, resolution) => {
	if (isEmpty(flight) || get(flight, "legs", []).length === 0) {
		return [];
	}
	const isMedium = resolution === RESOLUTION.MEDIUM;
	const firstLeg = first(flight.legs);
	const lastLeg = last(flight.legs);

	const indexStopOver = flight.legs.findIndex(leg => leg?.to?.isStopOver === true);
	let departStopOverLeg = {};
	let arrivalStopOverLeg = {};

	const arrivalStopCount = indexStopOver > 0 ? indexStopOver : 0;
	const departureStopCount =
		indexStopOver < flight.legs.length - 1 ? flight.legs.length - indexStopOver - 2 : 0; // If indexStopOver < legs.length - 1, there are legs between departStopOverLeg and lastLeg. Number of legs is flight.legs.length - indexStopOver - 2.
	if (indexStopOver === -1) {
		return buildItineraryPreviewData(flight);
	}

	departStopOverLeg = flight.legs[indexStopOver];
	arrivalStopOverLeg = flight.legs[indexStopOver + 1] || null;

	const stopOverDayCount = flight?.stopOverDuration + 1;
	const stopOverCountry = flight?.stopOverCity;

	const departureLegInfo = legInformation(departureStopCount);
	const arrivalLegInfo = legInformation(arrivalStopCount);
	const stopOverLegInfo = stopOverDayCount && (
		<Typography variant={TYPOGRAPHY_VARIANTS.SMALL} className="stop-duration" isBold>
			<FormattedPlural
				value={stopOverDayCount}
				one={
					<FormattedMessage
						values={{
							days: (
								<FormattedMessage
									values={{ duration: stopOverDayCount }}
									id="general.duration.days.singular"
								/>
							),
							country: stopOverCountry,
						}}
						id={
							isMedium
								? "stop.over.flight.duration.label"
								: "stop.over.flight.duration.label.long"
						}
					/>
				}
				other={
					<FormattedMessage
						values={{
							days: (
								<FormattedMessage
									values={{ duration: stopOverDayCount }}
									id="general.duration.days.plural"
								/>
							),
							country: stopOverCountry,
						}}
						id={
							isMedium
								? "stop.over.flight.duration.label"
								: "stop.over.flight.duration.label.long"
						}
					/>
				}
			/>
		</Typography>
	);

	const timelineData = [];
	const departEvent = {
		type: ITINERARY_INFO_TYPE.SCHEDULE,
		data: {
			airport: firstLeg.from.airport,
			iata: firstLeg.from.iata,
			iataCity: firstLeg.from.iataCity,
			date: firstLeg.from.takeOffDate,
			time: firstLeg.from.takeOffTime,
		},
		flightType: FLIGHT_TYPE.DEPARTURE,
	};

	const departStopOverEvent = {
		type: ITINERARY_INFO_TYPE.STOPOVER_SCHEDULE,
		data: {
			airport: departStopOverLeg?.to?.airport,
			iata: departStopOverLeg?.to?.iata,
			iataCity: departStopOverLeg?.to?.iataCity,
			date: departStopOverLeg?.to?.landingDate,
			time: departStopOverLeg?.to?.landingTime,
		},
		flightType: FLIGHT_TYPE.ARRIVAL,
	};

	const arrivalStopOverEvent = {
		type: ITINERARY_INFO_TYPE.STOPOVER_SCHEDULE,
		data: {
			airport: arrivalStopOverLeg?.from?.airport,
			iata: arrivalStopOverLeg?.from?.iata,
			iataCity: arrivalStopOverLeg?.from?.iataCity,
			date: arrivalStopOverLeg?.from?.takeOffDate,
			time: arrivalStopOverLeg?.from?.takeOffTime,
		},
		flightType: FLIGHT_TYPE.DEPARTURE,
	};

	const stopOverInfo = {
		type: ITINERARY_INFO_TYPE.STOPOVER_INFO,
		data: {
			legInfo: stopOverLegInfo,
		},
	};
	const arrivalEvent = {
		type: ITINERARY_INFO_TYPE.SCHEDULE,
		data: {
			airport: lastLeg.to.airport,
			iata: lastLeg.to.iata,
			iataCity: lastLeg.to.iataCity,
			date: lastLeg.to.landingDate,
			time: lastLeg.to.landingTime,
		},
		flightType: FLIGHT_TYPE.ARRIVAL,
	};

	const departureFlightInfo = {
		type: ITINERARY_INFO_TYPE.FLIGHT_INFO,
		data: {
			stopCount: departureStopCount,
			legInfo: departureLegInfo,
		},
	};

	const arrivalFlightInfo = {
		type: ITINERARY_INFO_TYPE.FLIGHT_INFO,
		data: {
			stopCount: arrivalStopCount,
			legInfo: arrivalLegInfo,
		},
	};

	timelineData.push(departEvent);
	timelineData.push(departureFlightInfo);
	timelineData.push(departStopOverEvent);
	timelineData.push(stopOverInfo);
	timelineData.push(arrivalStopOverEvent);
	timelineData.push(arrivalFlightInfo);
	timelineData.push(arrivalEvent);

	return timelineData;
};
